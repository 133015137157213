<template>
    <div :class="['intro', isMobile()?'mb-content':'']">
        <h3>集团简介</h3>
        <p class="title">GROUP PROFILE</p>
        <div class="text" v-html="introduceObj.content"></div>
        <!-- <div class="text">
            广西中科信恒投资集团有限公司（以下简称中科信恒集团）成立于2015年，立足南宁，旗下拥有10家全资或控股子公司，注册资金5000万，总资产达20亿元。<br>
        </div>
        <div class="text">
            中科信恒集团是在国家倡导推广政府与社会资本合作建设公共基础设施或公共物品和服务（PPP）的大背景下孕育而生，6年来集团始终聚焦国家新兴产业布局，充分发挥具有竞争优势的商务资源、投融资能力、项目全过程咨询和运营服务能力，以投资为纽带，资源整合为路径，创新多产业综合类PPP为模式，提供PPP项目全流程一体化服务，在广西、山西成功打造多个涵盖环保、教育、科技、文旅等综合类PPP示范项目，立志为提高社会公共产品和服务的效率，提高政府财政资源效率，促进政府职能转型多做贡献，真正实现政企共享共赢。
        </div> -->
    </div>
</template>
<script>
import { getIntroduce } from '@/api/about'
import { ref } from '@vue/reactivity';
export default {
    setup() {
        const introduceObj = ref({})

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        const getIntroduceList = async() => {
            let data = await getIntroduce({placeType: 1})
            if(data.status === 200) {
                introduceObj.value = data.data.data
                introduceObj.value.content = introduceObj.value.content.replace(
                    /\/ueditor/g,
                    "http://ueditor.aixuesheng.net/ueditor"
                );
            }
        }
        getIntroduceList()

        return {
            isMobile,
            introduceObj
        }
    },
}
</script>
<style>
    .intro .text img {
        max-width: 100% !important;
    }
    .mb-content .text img {
        max-width: 100% !important;
        display: inherit;
    }
</style>
<style lang="scss" scoped>
    .intro {
        margin-top: 60px;
        margin-bottom: 40px;
        h3 {
            font-size: 36px;
            text-align: center;
            letter-spacing: 3px
        }
        .title {
            font-size: 20px;
            text-align: center;
            color: #666;
            margin-top: 8px;
        }
        .text {
            text-indent: 28px;
            line-height: 2.2em;
            padding: 0 20px;
        }
    }
    .mb-content {
        margin-top: 20px;
        h3 {
            font-size: 0.4rem;
        }
        .text {
            font-size: 0.28rem;
            margin-top: 10px;
        }
        .title {
            font-size: 0.3rem;
            margin-top: 3px;
            padding: 0.3rem;
        }
    }
</style>