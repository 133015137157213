import { get } from '@/utils/http'

export const getHistory = (data) => {
    return get('group/getHistory', data)
}

export const getHonor = (data) => {
    return get('group/getHonor', data)
}

export const getIntroduce = (data) => {
    return get('group/getIntroduce', data)
}
